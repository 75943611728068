import getConfig from 'next/config';
import { Configuration } from '~/libs/api/configuration';
import { MessagingMarketplaceApi } from '~/libs/api/apis/messaging-marketplace-api';
import { getApiConfig } from '~/src/api/getApiConfig';

const apiConfiguration = getApiConfig();

const INBOX_API = new MessagingMarketplaceApi(apiConfiguration, apiConfiguration.basePath);

export const getUnreadMessages = () => INBOX_API.apiV1MarketplaceMessagingUnreadMessagesGet();
